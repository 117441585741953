import styled, { css } from 'styled-components'
import {
  padding,
  space,
  layout,
  flexbox,
  color,
  SpaceProps,
  LayoutProps,
  FlexboxProps,
  ColorProps,
} from 'styled-system'
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from 'styles/breakpoints'

export type WrapperProps = React.ComponentProps<typeof Wrapper>

export const Wrapper = styled.div<
  SpaceProps & LayoutProps & FlexboxProps & ColorProps
>`
  ${space}
  ${layout}
  ${flexbox}
  ${color}
  ${padding}
  
  ${({ horizontal, spacing }) =>
    spacing &&
    (horizontal
      ? css`
          & > * + * {
            margin-left: ${spacing}px;
          }
        `
      : css`
          & > * + * {
            margin-top: ${spacing}px;
          }
        `)}

  ${({ hideMobile }: { hideMobile: boolean }) =>
    hideMobile &&
    css`
      @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
        display: none;
      }
    `};

  ${({ hideTablet }: { hideTablet: boolean }) =>
    hideTablet &&
    css`
      @media screen and (max-width: ${DESKTOP_BREAKPOINT}px) {
        display: none;
      }
    `};

  ${({ fullWidth }: { fullWidth: boolean }) =>
    fullWidth &&
    css`
      width: 100%;
    `};

  ${({ textCenter }: { textCenter: boolean }) =>
    textCenter &&
    css`
      text-align: center;
    `};

  ${({ relative }: { relative: boolean }) =>
    relative &&
    css`
      position: relative;
    `};

  ${({ gap }: { gap: string }) =>
    gap &&
    css`
      gap: ${gap};
    `};

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding}px;
    `};

  ${({ cursor }) =>
    padding &&
    css`
      cursor: ${cursor};
    `};
`
