import dynamic from 'next/dynamic'

const BorderedButton = dynamic(() =>
  import('./BorderedButton').then((res) => res.BorderedButton)
)
const CollapseButton = dynamic(() =>
  import('./CollapseButton').then((res) => res.CollapseButton)
)
const EditorButton = dynamic(() =>
  import('./EditorButton').then((res) => res.EditorButton)
)
const OptionButton = dynamic(() =>
  import('./OptionButton').then((res) => res.OptionButton)
)
const PrimaryButton = dynamic(() =>
  import('./PrimaryButton').then((res) => res.PrimaryButton)
)
const SecondaryButton = dynamic(() =>
  import('./SecondaryButton').then((res) => res.SecondaryButton)
)
const TransparentButton = dynamic(() =>
  import('./TransparentButton').then((res) => res.TransparentButton)
)

export interface BaseButtonProps {
  label?: any
  disabled?: boolean
  center?: boolean
  rounded?: boolean
  small?: boolean
  fullWidth?: boolean
  fitWidth?: boolean
  inline?: boolean
  size?: 'sm' | 'md' | 'lg'
  withDropdown?: boolean
  loading?: boolean
  noWrap?: boolean
  icon?: any
  iconPosition?: string
  highlight?: boolean
  children?: React.ReactNode
  brandColors?: { bg: string; text: string }
}

export const BUTTON_VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
  transparent: 'transparent',
  option: 'option',
  editor: 'editor',
  collapse: 'collapse',
  bordered: 'bordered',
}

export interface ButtonProps extends BaseButtonProps {
  variant?: string
  [property: string]: any
}

export const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  ...props
}) => {
  const variants = {
    primary: <PrimaryButton {...props} />,
    secondary: <SecondaryButton {...props} />,
    transparent: <TransparentButton {...props} />,
    option: <OptionButton {...props} />,
    editor: <EditorButton {...props} />,
    collapse: <CollapseButton {...props} />,
    bordered: <BorderedButton {...props} />,
  }

  return variants[variant]
}
