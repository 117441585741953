import styled, { css } from 'styled-components'
import {
  PHONE_BREAKPOINT,
  TABLET_BREAKPOINT,
  DESKTOP_BREAKPOINT,
} from 'styles/breakpoints'
import { isFirefox } from 'react-device-detect'
import { memo } from 'react'

export const PageWrapper = styled.div<{
  // Allows for full page scrolling, eg. Dashboard page
  scroll?: boolean
  displayBlock?: boolean
  fitContent?: boolean
  noPaddingTop?: boolean
  isUsingHoverScroll?: boolean
  center?: boolean
}>`
  width: ${DESKTOP_BREAKPOINT}px;
  position: relative;
  display: ${({ displayBlock }) => (displayBlock ? 'block' : 'flex')};
  flex-wrap: wrap;
  justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
  z-index: 1;
  background: ${({ theme }) => theme.background.primary};
  padding-top: ${({ noPaddingTop }) => (noPaddingTop ? '0px' : '10px')};
  height: ${({ fitContent }) => (fitContent ? 'fit-content' : '100%')};
  ${({ scroll }) =>
    scroll &&
    css`
      overflow-y: scroll;
    `}
  ${({ isUsingHoverScroll }) =>
    isUsingHoverScroll &&
    css`
      overflow-y: scroll;
      ::-webkit-scrollbar-thumb {
        background: transparent;
      }
      scrollbar-color: transparent transparent;
      :hover {
        ::-webkit-scrollbar-thumb {
          background: ${({ theme }) => theme.border};
        }
        scrollbar-color: ${({ theme }) => theme.border} transparent;
      }
    `}
  @media screen and (max-width: ${DESKTOP_BREAKPOINT}px) {
    padding: 10px;
    width: 100%;
  }
  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 0 10px;
  }
`

export const PageContent = styled.div<{
  size?: string
  chat?: boolean
  noScroll?: boolean
  fullWidth?: boolean
  wider?: boolean
  marginTop?: number
  overflowHidden?: boolean
  dashboard?: boolean
  width?: string
  gap?: string
  fitContent?: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: ${({ size }) => (size === 'md' ? '3' : '1')};
  height: ${({ marginTop, noScroll }) => {
    if (noScroll) {
      return '100%'
    }
    if (marginTop) {
      return 'calc(100% - ' + marginTop + 'px)'
    }
    return 'unset'
  }};
  min-height: 100%;
  width: ${({ dashboard, width }) => {
    if (dashboard) return '50%'
    if (width) return width
    return 'unset'
  }};
  ${({ gap }) => gap && `gap: ${gap};`}
  ${({ noScroll }) =>
    noScroll &&
    css`
      margin-bottom: 0;
    `}
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
  ${({ wider }) =>
    wider &&
    css`
      width: 65%;
    `};
  ${({ overflowHidden }) =>
    overflowHidden &&
    css`
      overflow: hidden;
    `}
  ${({ fitContent }) =>
    fitContent &&
    css`
      height: fit-content;
    `}

  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`

export const PageContentWrapper = styled.div<{
  isUsingHoverScroll?: boolean
  overflowHidden?: boolean
}>`
  overflow-x: hidden;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 10px;
  ${({ overflowHidden }) =>
    overflowHidden &&
    css`
      overflow: hidden !important;
    `}
  ${({ isUsingHoverScroll }) =>
    isUsingHoverScroll
      ? css`
          overflow-y: visible;
          padding-right: 5px;
          ::-webkit-scrollbar-thumb {
            background: transparent;
          }
          scrollbar-color: transparent transparent;
          :hover {
            ::-webkit-scrollbar-thumb {
              background: ${({ theme }) => theme.border};
            }
            scrollbar-color: ${({ theme }) => theme.border} transparent;
          }
          @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
            ::-webkit-scrollbar-thumb {
              background: ${({ theme }) => theme.border};
            }
            scrollbar-color: ${({ theme }) => theme.border} transparent;
          }
        `
      : css`
          overflow: inherit;
        `};
  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 0px;
    margin-top: 0px;
    -webkit-overflow-scrolling: touch;
  }
`

export const PageSidebar = memo(styled.div<{
  size?: string
  chat?: boolean
  heightUnset?: boolean
  fullHeight?: boolean
  hideMobile?: boolean
  noScroll?: boolean
  wider?: boolean
  noPadding?: boolean
  noBorder?: boolean
  marginTop?: number
  isUsingHoverScroll?: boolean
  left?: boolean
  bottom?: boolean
  transparentBackground?: boolean
  flex?: boolean
}>`
  display: ${({ flex }) => (flex ? 'flex' : 'unset')};
  flex: ${({ size }) => (size === 'md' ? '2' : '1')};
  position: sticky;


  top: 0;
  border-radius: 5px;
  border: 1px solid ${({ theme, noBorder }) =>
    noBorder ? 'transparent' : theme.border};
  background-color: ${({ theme, transparentBackground }) =>
    transparentBackground ? 'transparent' : theme.background.secondary};
  padding: ${({ noPadding }) => (noPadding ? '0px' : '20px')};
  height: ${({ heightUnset }) => (heightUnset ? 'unset' : 'fit-content')};
  max-height: calc(100% - ${({ chat, left, marginTop }) =>
    chat || left ? '10px)' : (70 + marginTop ? marginTop : 0) + 70 + 'px)'};
  margin-left: ${({ left, bottom }) => (left ? '0' : bottom ? '0px' : '10px')};
  margin-right: ${({ left }) => (left ? '10px' : '0')};
  margin-bottom: ${({ bottom }) => (bottom ? '10px' : '0')};
  overflow: ${isFirefox ? 'auto' : 'hidden'};
  ${({ isUsingHoverScroll }) =>
    isUsingHoverScroll
      ? css`
          overflow-y: scroll;
          overflow-x: hidden;
          ::-webkit-scrollbar-thumb {
            background: transparent;
          }
          scrollbar-color: transparent transparent;
          :hover {
            ::-webkit-scrollbar-thumb {
              background: ${({ theme }) => theme.border};
            }
            scrollbar-color: ${({ theme }) => theme.border} transparent;
          }
          @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
            ::-webkit-scrollbar-thumb {
              background: ${({ theme }) => theme.border};
            }
            scrollbar-color: ${({ theme }) => theme.border} transparent;
          }
        `
      : css`
          :hover {
            overflow-y: ${!isFirefox ? 'overlay' : 'auto'};
          }
        `};
  ${({ fullHeight }) =>
    fullHeight
      ? css`
          bottom: 0;
        `
      : css`
          height: fit-content;
        `};
  ${({ noScroll }) =>
    noScroll &&
    css`
      overflow-y: hidden;
    `};
  ${({ wider }) =>
    wider &&
    css`
      width: 35%;
    `};
  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    & > * {
      padding: 13px;
    }
    ${({ hideMobile }) =>
      hideMobile &&
      css`
        display: none;
      `};
  }
`)

export const PageTabletBackdrop = styled.div<{ show?: boolean }>`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.background.primary};
  z-index: 100;
  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ show }) =>
      show &&
      css`
        display: block;
      `}
  }
`

export const PageMobilePopover = styled.div<{ show?: boolean }>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.background.primary};
  padding: 18px;
  margin-top: 0;
  overflow-y: scroll;
  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    max-width: 560px;
    width: 100%;
    background-color: ${({ theme }) => theme.background.secondary};
    z-index: 120;
    left: 50%;
    top: 0px;
    bottom: 12px;
    transform: translateX(-50%);
    height: auto;
    overflow: scroll;
    ${({ show }) =>
      show &&
      css`
        display: block;
      `}
  }
  @media screen and (max-width: ${PHONE_BREAKPOINT}px) {
    max-width: 90%;
  }
`

export const PageMobilePopoverHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 4px;
  svg {
    color: ${({ theme }) => theme.primary};
  }
`

export const PageMobilePopoverClose = styled.div`
  border-radius: 100%;
  width: 56px;
  height: 56px;
  position: fixed;
  bottom: 12px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.background.primary};
  border: 1px solid ${({ theme }) => theme.border};
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.primary};
  }
  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    top: 12px;
    right: 12px;
  }
`
